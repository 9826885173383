<template>
  <eden-confirm-dialog
    :title="'Delete Menu Type'"
    :button-type="'danger'"
    :button-text="'Yes, Delete'"
    :cancel-text="'No, Cancel'"
    :button-status="deleting"
    :show.sync="setShow"
    @confirm="deleteMenuType"
  >
    <p>Are you sure you want to delete this menu type?</p>
  </eden-confirm-dialog>
</template>

<script>
import menu_types from "@/requests/services/meal/menu_types";

export default {
  name: "menuTypeDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    deleteMenuType() {
      this.deleting = true;
      menu_types
        .delete(this.id)
        .then((response) => {
          if (response.data.status) {
            this.deleting = false;
            this.$message.success(response.data.message);
            this.$emit("success");
            this.setShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.deleting = false;
          this.setShow = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
