<template>
  <el-dialog
    :title="`${formatText(action)} Menu Type`"
    :visible="shouldShow"
    @close="closeModal"
    width="35%"
  >
    <el-form :model="form" label-position="top">
      <el-row>
        <el-col>
          <el-form-item label="Company Name">
            <el-select
              v-model="form.company_id"
              filterable
              placeholder="Select a company from the dropdown"
              value-key="id"
            >
              <el-option
                v-for="(item, index) in company"
                :value="item.id"
                :key="index"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="Menu Name" prop="menu_name">
            <el-input
              class=""
              v-model="form.name"
              type="text"
              placeholder="Name"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-form-item label="Description" prop="description">
            <el-input
              v-model="form.description"
              :rows="4"
              maxlength="200"
              show-word-limit
              type="textarea"
              placeholder="Please input"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="Subscription Price" prop="subscription_price">
            <el-input v-model="form.subscription_price" type="number" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="action === 'add'"
        type="primary"
        :loading="loading"
        :disabled="!isFormComplete"
        @click="createMenuTypes"
        >Done</el-button
      >
      <el-button
        v-else
        type="primary"
        :loading="updating"
        :disabled="!isFormChanged"
        @click="update"
        >Save Changes</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import menu_types from "@/requests/services/meal/menu_types";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    menuType: {
      type: Object,
      default() {
        return {};
      },
    },
    company: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      form: {
        name: "",
        description: "",
        subscription_price: null,
        company_id: null,
      },
      formData: {},
      businessType: [],
      loading: false,
      updating: false,
    };
  },

  watch: {
    show() {
      if (this.show) {
        if (this.action === "edit") {
          this.prefillForm();
        }
      }
    },
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isFormComplete() {
      return (
        this.form.name &&
        this.form.description &&
        this.form.subscription_price !== null &&
        this.form.company_id !== null
      );
    },
    isFormChanged() {
      return (
        this.form.name !== this.formData.name ||
        this.form.description !== this.formData.description ||
        this.form.subscription_price !== this.formData.subscription_price ||
        this.form.company_id !== this.formData.company.id
      );
    },
  },
  created() {
    if (this.action === "edit") {
      this.prefillForm();
    }
  },

  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form = {
        description: "",
        name: "",
        subscription_price: null,
        company_id: null,
      };
    },
    prefillForm() {
      this.formData = this.menuType;
      this.form = {
        name: this.formData.name,
        description: this.formData.description,
        subscription_price: this.formData.subscription_price,
        company_id: this.formData.company.id,
      };
    },

    createMenuTypes() {
      this.loading = true;
      menu_types
        .create(this.form)
        .then((response) => {
          this.$message.success(response.data.message);
          this.$emit("success");
          this.closeModal();
          this.loading = false;
          console.log(response, "response");
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else if (error.response.status == 500) {
            console.log(error.response, "error");
            this.$message.error(
              "Subscription price is too high, must be below #1,000,000",
            );
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    update() {
      this.updating = true;
      menu_types
        .update(this.menuType.id, this.form)
        .then((response) => {
          this.$message.success(response.data.message);
          this.$emit("success");
          this.closeModal();
          this.updating = false;
          console.log(response, "response");
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          console.log(error.response, "error");
        });
    },
  },
};
</script>

<style scoped>
/* .upload-image {
  height: 112px !important;
  width: 100% !important;
} */
</style>
