<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <el-button
          v-if="allowAction"
          type="primary"
          @click="command({ action: 'add' })"
        >
          Add New Menu Type
        </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <el-table v-if="pageData.length" :data="pageData" :key="sort.property">
        <el-table-column min-width="300">
          <template #header>
            <eden-table-column-header
              :label="'Menu Types'"
              :property="'name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'name')"
            />
          </template>
          <template v-slot="scope">
            <p class="text-bold">{{ formatToTitleCase(scope.row?.name) }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="150">
          <template #header>
            <eden-table-column-header
              :label="'Company'"
              :property="'company_name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'company_name')"
            />
          </template>
          <template v-slot="scope">
            <p>{{ formatToTitleCase(scope.row.company?.company_name) }}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="150">
          <template #header>
            <eden-table-column-header
              :label="'Subscription Price'"
              :property="'subscription_price'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'subscription_price')"
            />
          </template>
          <template v-slot="scope">
            <p>{{ formatPrice(scope.row.subscription_price) }}</p>
          </template>
        </el-table-column>
        <el-table-column v-if="allowAction" width="60">
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{
                    action: 'edit',
                    id: scope.row.id,
                    index: scope.$index,
                    menuType: scope.row,
                  }"
                  >Edit Menu Type</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{
                    action: 'delete',
                    id: scope.row.id,
                    index: scope.$index,
                  }"
                  class="text-danger"
                  >Delete Menu Type</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No menu types'" />
    </template>

    <menu-type-delete
      :show.sync="showMenuTypeDelete"
      :id="menuTypeId"
      @success="getMenuTypes()"
    />
    <menu-type-form
      :action="menuType.action"
      :show.sync="menuType.visibility"
      :menu-type="menuType.data"
      :company="companies"
      @success="getMenuTypes()"
    />
  </div>
</template>

<script>
import MenuTypeForm from "@/components/Services/Meal/MenuTypes/MenuTypeForm";
import MenuTypeDelete from "@/components/Services/Meal/MenuTypes/MenuTypeDelete";
import menu_types from "@/requests/services/meal/menu_types";

export default {
  name: "MenuType",
  components: { MenuTypeDelete, MenuTypeForm },
  data() {
    return {
      loading: false,
      pageData: [],
      companies: [],
      showMenuTypeDelete: false,
      menuType: {
        action: "add",
        visibility: false,
        data: {},
      },
      sort: {
        property: "name",
        direction: "asc",
      },
      menuTypeId: "",
    };
  },
  computed: {
    title() {
      return `${this.pageData.length} Menu Types`;
    },
    allowAction() {
      return this.allowAccessFor(["admin"]);
    },
  },
  created() {
    this.getMenuTypes();
    this.getCompanies();
  },
  methods: {
    getMenuTypes() {
      this.loading = true;
      menu_types
        .getMenuTypes()
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.pageData = data.data;
            this.loading = false;
          } else {
            this.$message.error("Error loading Menu Types.");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
          this.loading = false;
        });
    },
    getCompanies() {
      this.loading = true;
      menu_types
        .getCompanies()
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.companies = data.data;
            this.loading = false;
          } else {
            this.$message.error("Error loading Companies.");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
          this.loading = false;
        });
    },
    command(command) {
      this.menuTypeId = command.id;
      switch (command.action) {
        case "add":
          this.menuType.data = {};
          this.menuType.action = "add";
          this.menuType.visibility = true;
          break;
        case "edit":
          this.menuType.data = command.menuType;
          this.menuType.action = "edit";
          this.menuType.visibility = true;
          break;
        case "delete":
          this.menuType.data = {};
          this.showMenuTypeDelete = true;
          break;
        default:
          break;
      }
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.pageData = this.sortList(this.pageData, property, direction);
    },
  },
};
</script>
